import React from 'react';
import { Carousel } from 'antd';

import Layout from '../components/layout';
import styles from './gallery.module.css';

export default ({ pageContext: { header, images } }) => {
  return (
    <Layout>
      <div className={styles.root}>
        <h1>{header}</h1>
        <Carousel centerMode swipe autoplay variableWidth>
          {images.map(({ file: { url }, description }) => (
            <div key={url}>
              <img src={url} alt={description} />
            </div>
          ))}
        </Carousel>
      </div>
    </Layout>
  );
};
